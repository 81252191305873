import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useTimesheet } from "@/store/timesheet.store";
import { useItemTypes } from "@/store/itemTypes.store";
import { useNews } from "@/store/news.store";

import { dateToString } from "@/utils";

export function useHomePage() {
  const date = ref(new Date());
  const route = useRoute();

  const userId = computed(() => route.params.id?.toString());
  const dateString = computed(() => dateToString(date.value));

  const timesheet = useTimesheet();
  const types = useItemTypes();
  const news = useNews();

  function loadTimesheet() {
    return timesheet.loadTimesheet(userId.value, dateString.value);
  }

  function loadTypes() {
    return types.loadItemTypes(userId.value);
  }

  function loadNews(page?: number, size?: number) {
    return news.loadNews(userId.value, page, size);
  }

  function loadData() {
    return Promise.all([loadTimesheet(), loadTypes(), loadNews(0, 1)]);
  }

  watch(
    () => route.params.id,
    () => loadData(),
    { immediate: true }
  );

  return {
    date,
    route
  };
}
