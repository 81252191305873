
import { defineComponent } from "vue";

import PCard from "@/components/PCard.vue";
import PNotAvailable from "@/components/PNotAvailable.vue";

export default defineComponent({
  name: "PTemperature",
  components: { PNotAvailable, PCard }
});
