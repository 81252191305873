
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { useTimesheet } from "@/store/timesheet.store";
import { useItemTypes } from "@/store/itemTypes.store";

import { dateToString } from "@/utils";

import PHeader from "@/components/PHeader.vue";
import PFooter from "@/components/PFooter.vue";
import PGrid from "@/components/PGrid.vue";
import PMain from "@/components/PMain.vue";
import PCalendar from "@/components/calendar/PCalendar.vue";
import PTemperature from "@/components/PTemperature.vue";
import PNewspaper from "@/components/PNewspaper.vue";
import PChat from "@/components/PChat.vue";
import PMenu from "@/components/PMenu.vue";

import UiSkeletonCalendar from "@/components/ui/skeletons/UiSkeletonCalendar.vue";
import { useNews } from "@/store/news.store";
import { useHomePage } from "@/composable/useHomePage";

export default defineComponent({
  name: "Home",
  components: {
    UiSkeletonCalendar,
    PMenu,
    PFooter,
    PGrid,
    PChat,
    PNewspaper,
    PTemperature,
    PCalendar,
    PMain,
    PHeader
  },

  setup() {
    const homePage = useHomePage();

    return {
      data: homePage.date,
      route: homePage.route
    };
  }
});
